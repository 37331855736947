import React from "react";

const MegaMenuATank = () => {
  return (
    <ul className="navbar-nav">
      <li className="nav-item position-static active">
        <button className="nav-link">Home</button>
      </li>
      {/* End li */}

      <li className="nav-item position-static">
        <button className="nav-link">Feature</button>
      </li>
      {/* End li */}

      <li className="nav-item position-static">
        <button className="nav-link">Pricing</button>
      </li>
      {/* End li */}

      <li className="nav-item position-static">
        <button className="nav-link">FAQ</button>
      </li>
      {/* End li */}
    </ul>
  );
  // End navbar nav mega menu main
};

export default MegaMenuATank;
